import React, {useCallback, useState} from 'react';
import graphql from 'babel-plugin-relay/macro';

import * as yup from 'yup';

import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {ConfigService, LoginService} from '../../../utils';
import {useAuthContext} from '../../../context/AuthContext';
import {useHistory} from 'react-router';
import {useMutation} from 'react-relay-mutation';
import {SignupFormMutation} from '../../../__generated__/SignupFormMutation.graphql';
import {PhoneNumberFormikField} from '../../../components/textField/PhoneNumberTextfield';
import {AcceptTermsField} from './AcceptTermsField';
import GoogleLoginButton from '../../../components/button/GoogleLoginButton';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import TurnstileWidget from './TurnstileWidget';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useAuthSearchParams} from '../../../hooks/useAuthSearchParams';

const loginService = new LoginService(
  ConfigService.serverUri || 'http://localhost:5000'
);

interface SignupValues {
  email: string;
  password: string;
  confirmPassword: string;
  firstname: string;
  surname: string;
  contactNumber: string | null | undefined;
  acceptedTerms: boolean;
  turnstileToken: string | null | undefined;
}

const signupValidationSchema: yup.SchemaOf<SignupValues> = yup.object({
  email: yup
    .string()
    .trim()
    .email('Not a valid email.')
    .required('Email is a required field.'),
  password: yup
    .string()
    .required('Password is a required field.')
    .min(8, 'Password must be at least 8 characters long.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[0-9]/, 'Password must contain at least one number.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is a required field.'),
  firstname: yup
    .string()
    .trim()
    .max(30, 'Maximum characters reached')
    .required('Name is a required field.'),
  surname: yup
    .string()
    .trim()
    .max(30, 'Maximum characters reached')
    .required('Surname is a required field.'),
  contactNumber: yup.string(),
  acceptedTerms: yup
    .boolean()
    .oneOf([true], 'Please accept the terms and conditions.')
    .required('Please accept the terms and conditions.'),
  turnstileToken: yup.string(),
});

export function SignupForm() {
  const {handleLogin} = useAuthContext();
  const {push} = useHistory();
  const [loginStatus, setLoginStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {gToken, inviteToken, referredBy} = useAuthSearchParams();

  const [userRegister] = useMutation<SignupFormMutation>(
    graphql`
      mutation SignupFormMutation($input: UserRegisterInput!) {
        userRegister(input: $input) {
          user {
            username
          }
        }
      }
    `
  );

  const handleSubmit = useCallback<FormOnSubmit<SignupValues>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);
      setLoginStatus('loading');

      try {
        const {
          email,
          password,
          firstname,
          surname,
          contactNumber,
          turnstileToken,
        } = values;

        const response = await userRegister({
          variables: {
            input: {
              username: email.toLowerCase().trim(),
              password,
              inviteToken,
              referredBy,
              name: firstname.trim(),
              surname: surname.trim(),
              contactNumber,
              turnstileToken: turnstileToken ?? '',
              acceptedTerms: true,
            },
          },
        });
        if (response) {
          const result = await loginService.login(
            email.toLowerCase(),
            password
          );
          if (result) {
            handleLogin(email, result.access_token, 'truvu');
            push('/');
            setLoginStatus('success');
            return;
          }
        }
        setLoginStatus('error');
      } catch (e) {
        const catchErrorMessage = e?.source?.errors?.[0]?.message;
        if (typeof catchErrorMessage === 'string') {
          setErrorMessage(catchErrorMessage);
        }
        setLoginStatus('error');
      } finally {
        setSubmitting(false);
      }
    },
    [handleLogin, push, userRegister, inviteToken, referredBy]
  );

  return (
    <Form<SignupValues>
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        surname: '',
        contactNumber: '',
        turnstileToken: '',
        acceptedTerms: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={signupValidationSchema}
    >
      <TruvuMessageDialog
        title={'Something went wrong...'}
        variant={'error'}
        isOpen={loginStatus === 'error'}
        message={
          <Stack>
            <Typography variant="h6" gutterBottom>
              {errorMessage ?? 'Unfortunately we were unable to sign you up.'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              For more information contact{' '}
              <a href="mailto: support@truvu.app">support@truvu.app</a>
            </Typography>
          </Stack>
        }
        actions={
          <>
            {errorMessage === 'Username already taken' && (
              <TruvuButton to={'/password/reset/request'} variant={'primary'}>
                Forgot Password
              </TruvuButton>
            )}
            <TruvuButton
              variant={'secondary'}
              onClick={() => {
                setLoginStatus('idle');
              }}
            >
              Close
            </TruvuButton>
          </>
        }
        onClose={() => {
          setLoginStatus('idle');
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
      />
      <TruvuTextField name="email" placeholder="E-mail" formikField />
      <TruvuTextField name="firstname" placeholder="Firstname" formikField />
      <TruvuTextField name="surname" placeholder="Surname" formikField />
      <PhoneNumberFormikField name="contactNumber" label="Contact Number" />
      <TruvuTextField
        name="password"
        placeholder="Password"
        isPassword
        formikField
      />
      <TruvuTextField
        name="confirmPassword"
        placeholder="Re-enter password"
        isPassword
        formikField
      />
      <AcceptTermsField />
      <TurnstileWidget />
      <TruvuButton sx={{mt: 1}} formikSubmit test-id={'UserRegisterButton'}>
        Sign up
      </TruvuButton>
      <DividerWithChildren sx={{my: 1}}>or</DividerWithChildren>
      <SignupWithGoogleButton
        referredBy={referredBy ?? ''}
        inviteToken={inviteToken ?? ''}
        gToken={gToken ?? ''}
      />
    </Form>
  );
}

interface SignupWithGoogleButtonProps {
  referredBy: string;
  inviteToken: string;
  gToken: string;
}

const SignupWithGoogleButton: React.FC<SignupWithGoogleButtonProps> = ({
  referredBy,
  inviteToken,
  gToken,
}) => {
  return (
    <GoogleLoginButton
      gToken={gToken}
      customState={{referredBy, inviteToken}}
    />
  );
};

const DividerWithChildren = styled(Divider)(() => ({
  '&::before, &::after': {
    borderTopWidth: '3px',
  },
}));
