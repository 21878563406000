import React, {useCallback, useMemo, useState} from 'react';
import * as yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useFragment} from 'relay-hooks';
import {useMutation} from 'react-relay-mutation';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {ProfileEditCompanyDetailsMutation} from '../../../__generated__/ProfileEditCompanyDetailsMutation.graphql';
import {ProfileEditCompanyDetails_user$key} from '../../../__generated__/ProfileEditCompanyDetails_user.graphql';

interface ProfileEditDetailsForm {
  name: string;
  address: string;
  vatNumber: string;
}

interface ProfileEditDetailsProps {
  companyRef: ProfileEditCompanyDetails_user$key;
  onCompleted: () => void;
  onCancel?: () => void;
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(30, 'Maximum characters reached')
    .required('Name is a required field.'),
  address: yup.string(),
  vatNumber: yup.string(),
});

const fragment = graphql`
  fragment ProfileEditCompanyDetails_user on Company {
    id
    logoimageurl
    name
    address
    vatno
    countrycode
  }
`;

export function ProfileEditCompanyDetails({
  companyRef,
  onCompleted,
  onCancel,
}: ProfileEditDetailsProps) {
  const {notify} = useSnackbarContext();
  const company = useFragment(fragment, companyRef);
  const [logoImage, setLogoImage] = useState<File | null>(null);

  const [profileSave] = useMutation<ProfileEditCompanyDetailsMutation>(
    graphql`
      mutation ProfileEditCompanyDetailsMutation($input: CompanySaveInput!) {
        companySave(input: $input) {
          company {
            logoimageurl
            name
            address
            vatno
          }
        }
      }
    `
  );

  const handleSubmit = useCallback<FormOnSubmit<ProfileEditDetailsForm>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);

      try {
        const input = {
          countryCode: company.countrycode ?? 'en_ZA',
          name: values.name,
          address: values.address,
          vatNo: values.vatNumber,
          logoImage: logoImage || undefined,
        };

        await profileSave({
          variables: {input},
          onCompleted,
        });

        notify({
          message: 'Profile saved successfully',
          variant: 'success',
          position: 'right',
        });
      } catch (e) {
        console.error('Error saving profile:', e);
        notify({
          message: 'Failed to save profile',
          variant: 'error',
          position: 'right',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [profileSave, company.countrycode, onCompleted, notify, logoImage]
  );

  const initialValues = useMemo<ProfileEditDetailsForm>(() => {
    return {
      name: company?.name ?? '',
      address: company?.address ?? '',
      vatNumber: company?.vatno ?? '',
    };
  }, [company]);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setLogoImage(event.target.files[0]);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleLogoChange}
        style={{marginBottom: '1rem'}}
      />
      {logoImage && (
        <img
          src={URL.createObjectURL(logoImage)}
          alt="Company Logo Preview"
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            marginBottom: '1rem',
          }}
        />
      )}
      <TruvuTextField name="name" label="Company Name" formikField />
      <TruvuTextField name="address" label="Company Address" formikField />
      <TruvuTextField name="vatNumber" label="VAT Number" formikField />
      <TruvuButton variant="primary" formikSubmit>
        Save
      </TruvuButton>
      {onCancel != null && (
        <TruvuButton variant="secondary" onClick={onCancel}>
          Cancel
        </TruvuButton>
      )}
    </Form>
  );
}
