import {LinearProgress, Stack, useMediaQuery} from '@mui/material';
import {captureMessage} from '@sentry/browser';

import React, {useEffect, useRef, useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {FrameType, FrameStoreBuffer} from '../types';
import {range} from 'lodash';
import {
  terminateExistingWorker,
  hasWebCodec,
  printIndex,
  sliceIntoChunks,
  logLoadProgress,
  logViewProgress,
  logWorkerPlan,
} from '../helpers';
import useWindowDimensions from '../hooks/useWindowDimensions';
import {styled, Theme} from '@mui/material/styles';
import {FullscreenLoader} from '../../../components/loader/FullscreenLoader';
import {useRefetchable} from 'relay-hooks';
import {TourPlayerCanvas_tour$key} from '../../../__generated__/TourPlayerCanvas_tour.graphql';
import {TourErrorDialog} from './TourErrorDialog';
import {TourStrugglingDialog} from './TourStrugglingDialog';
import {tourStrugglingTimeoutDuration} from '../tourStrugglingTimeoutDuration';
import {useSearchParam} from 'react-use';
import {LogoCanvas} from './LogoCanvas';

const fragment = graphql`
  fragment TourPlayerCanvas_tour on Tour
  @refetchable(queryName: "TourPlayerCanvasTodoRefetchQuery") {
    id
    title
    description
    videoURL
    thumbnailURL
    tourIntroLogoURL
    videoInfo {
      width
      height
      fullSize
      mediumSize
      smallSize
    }
    markers {
      id
      name
      description
      scrollPosition
      frameIndex
    }
    clips {
      id
      index
      frameCount
      duration
      highURI
      lowURI
    }
  }
`;

interface TourPlayerCanvasProps {
  getCanvasDimensions: (width: number, height: number) => void;
  tourCanvasRef: React.RefObject<HTMLCanvasElement>;
  tourRef: TourPlayerCanvas_tour$key;
  currentIndex: number;
  hiRes: boolean;
  setBuffer: React.Dispatch<React.SetStateAction<FrameStoreBuffer>>;
}

export function TourPlayerCanvas({
  getCanvasDimensions,
  tourCanvasRef,
  tourRef,
  currentIndex,
  hiRes = false,
  setBuffer,
}: TourPlayerCanvasProps) {
  const {data: tour} = useRefetchable(fragment, tourRef);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const fullScreenParam = useSearchParam('fullScreen');
  /** States */
  const [windowDimensions] = useWindowDimensions();
  const [store, setStore] = React.useState<
    Array<{
      frameInClusterId: number;
      frame: FrameType;
      clusterCount: number;
    }>
  >([]);
  const [lastFrameIndex, setLastFrameIndex] = React.useState(0);
  const [isLibLoaded, setIsLibLoaded] = React.useState(false);
  const [didErrorOccur, setDidErrorOccur] = useState(false);
  const [isStruggling, setIsStruggling] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  /** Memoized states */
  const framesStore = React.useMemo(
    () =>
      store
        .sort((a, b) => a.clusterCount - b.clusterCount)
        .map((frame, index) => ({
          frameIndex: index,
          frame: frame.frame,
          clusterCount: frame.clusterCount,
        })),
    [store]
  );

  const {totalImages, totalDuration} = React.useMemo(() => {
    return {
      totalImages:
        (tour?.clips?.reduce((accumulator, clip) => {
          return accumulator + clip.frameCount;
        }, 0) ?? 0) - 1,
      totalDuration:
        (tour?.clips?.reduce((accumulator, clip) => {
          return accumulator + clip.duration;
        }, 0) ?? 0) - 1,
    };
  }, [tour?.clips]);

  const progress = React.useMemo(
    () => Math.floor((currentIndex / totalImages) * 100),
    [currentIndex, totalImages]
  );

  const buffer = React.useMemo(() => {
    return (framesStore.length / totalImages) * 100;
  }, [framesStore.length, totalImages]);

  const {orientation, tourWidth, tourHeight} = React.useMemo(() => {
    let orientation = 'landscape';
    if (tour?.videoInfo?.width && tour?.videoInfo?.width) {
      if (tour?.videoInfo?.width < tour?.videoInfo?.height) {
        orientation = 'portrait';
      }
    }
    return {
      orientation,
      tourWidth: tour?.videoInfo?.width ?? 0,
      tourHeight: tour?.videoInfo?.height ?? 0,
    };
  }, [tour?.videoInfo?.width, tour?.videoInfo?.height]);

  const {canvasHeight, canvasWidth} = React.useMemo(() => {
    let canvasHeight: number;
    let canvasWidth: number;

    if (orientation === 'portrait') {
      canvasHeight = windowDimensions.height;
      canvasWidth = (tourWidth / tourHeight) * windowDimensions.height;
      if (
        windowDimensions.width > canvasWidth &&
        windowDimensions.height > windowDimensions.width
      ) {
        canvasWidth = windowDimensions.width;
        canvasHeight = (tourHeight / tourWidth) * windowDimensions.width;
      }
    } else {
      canvasWidth = windowDimensions.width;
      canvasHeight = (tourHeight / tourWidth) * windowDimensions.width;
    }

    if ((canvasWidth > 640 || canvasHeight > 960) && isDesktop) {
      canvasHeight = tourHeight * 0.5;
      canvasWidth = tourWidth * 0.5;
    }

    if (canvasWidth > 650) {
      canvasWidth = 650;
      canvasHeight = (tourHeight / tourWidth) * 650;
    }

    return {canvasHeight, canvasWidth};
  }, [isDesktop, orientation, tourHeight, tourWidth, windowDimensions]);

  /** Effects */
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsStruggling(true);
    }, tourStrugglingTimeoutDuration);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    getCanvasDimensions(canvasWidth, canvasHeight);
  }, [canvasHeight, canvasWidth, getCanvasDimensions]);

  React.useEffect(() => {
    setBuffer({
      storeLength: framesStore.length,
      totalImages,
      fps: totalImages / totalDuration,
    });
    return () => {
      setBuffer({totalImages: 0, storeLength: 0, fps: 0});
    };
  }, [framesStore.length, setBuffer, totalDuration, totalImages]);

  React.useEffect(() => {
    async function draw() {
      if (tourCanvasRef.current) {
        try {
          if (framesStore.length > 0) {
            printIndex(
              framesStore,
              Math.round(
                currentIndex >= framesStore[framesStore.length - 1].frameIndex
                  ? 0
                  : currentIndex
              ),
              tourCanvasRef.current
            );
          }
        } catch (e) {
          // TODO Handle Error
          return;
        }
      }
    }

    requestAnimationFrame(draw);
    if (
      Math.floor(currentIndex) % Math.floor(totalImages / 10) === 0 &&
      Math.floor(currentIndex) !== 0
    ) {
      logViewProgress(
        Math.floor((Math.floor(currentIndex) / totalImages) * 100),
        `${tour?.id}`
      );
    }
  }, [
    currentIndex,
    tourCanvasRef,
    framesStore,
    totalImages,
    tour?.id,
    tour?.tourIntroLogoURL,
    lastFrameIndex,
  ]);

  React.useEffect(() => {
    const loadLib = async () => {
      // Check if LibAVWebCodecs is already available
      // eslint-disable-next-line
      // @ts-ignore
      if (typeof LibAVWebCodecs !== 'undefined') {
        // eslint-disable-next-line
        // @ts-ignore
        await LibAVWebCodecs.load();
        setIsLibLoaded(true);
      }
    };

    loadLib();
  }, []);

  React.useEffect(() => {
    if (!isLibLoaded) {
      return;
    }
    const workerRefs: Array<React.MutableRefObject<Worker | null>> = [];
    const fetch = async () => {
      const numberOfWorkers = Math.floor(navigator.hardwareConcurrency / 3) + 1;
      // eslint-disable-next-line
      for (const _ of range(1, numberOfWorkers + 1)) {
        const workerRef = React.createRef<Worker | null>();
        workerRefs.push(workerRef);
      }

      // eslint-disable-next-line
      // @ts-ignore
      await LibAVWebCodecs.load();
      setStore([]);
      // setProgress(0);
      setLastFrameIndex(0);

      if (tour && tour.videoURL && tour.clips) {
        const clips = tour.clips
          .map((clip) => clip)
          .sort((a, b) => a.index - b.index);
        const clipUrls = sliceIntoChunks(
          clips.map((clip) => (hiRes ? clip.highURI : clip.lowURI)),
          numberOfWorkers
        );
        let wh = {
          width: !hiRes ? 640 : 1280,
          height: !hiRes ? 360 : 720,
        };
        if (tour.videoInfo && tour.videoInfo?.width < tour.videoInfo?.height) {
          wh = {
            width: !hiRes ? 360 : 720,
            height: !hiRes ? 640 : 1280,
          };
        }

        for (const workerRef of workerRefs) {
          const index = workerRefs.indexOf(workerRef);
          // eslint-disable-next-line
          // @ts-ignore
          if (hasWebCodec()) {
            await terminateExistingWorker(workerRef.current, store);

            workerRef.current = new Worker('WebCodecsWorker.js');
          } else {
            await terminateExistingWorker(workerRef.current, store);

            workerRef.current = new Worker('LibavjsPolyfillWorker.js');
          }

          workerRef.current.onerror = (error) => {
            // if a direct error occurs
            captureMessage(error.message);
          };

          workerRef.current.onmessage = (data) => {
            if (data.data && data.data.frames) {
              /** Clear and reset struggling timeout related state */
              setIsStruggling(false);
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              setStore((frames) => {
                logLoadProgress(
                  [...frames, ...data.data.frames].length,
                  totalImages,
                  `${tour?.id}`
                );
                return [...frames, ...data.data.frames];
              });
              setDidErrorOccur(false);
            } else if (data.data && data.data.type === 'error') {
              setDidErrorOccur(true);
              captureMessage(data.data.message);
            }
          };

          workerRef.current.postMessage({
            init: true,
            urls: clipUrls[index],
            ...wh,
          });
        }
        logWorkerPlan(workerRefs.length, hasWebCodec(), `${tour?.id}`);
      }
    };
    fetch();
    return () => {
      setStore([]);
      // setProgress(0);
      setLastFrameIndex(0);
      workerRefs.forEach((workerRef) => {
        terminateExistingWorker(workerRef.current, store);
      });
    };
    // eslint-disable-next-line
  }, [isLibLoaded]);

  if (
    framesStore.length === 0 ||
    (framesStore.length > 0 &&
      framesStore[0].frameIndex === 0 &&
      framesStore[0].clusterCount !== 0)
  ) {
    return (
      <>
        <TourErrorDialog
          isOpen={didErrorOccur}
          onClose={() => {
            setDidErrorOccur(false);
          }}
        />
        <TourStrugglingDialog
          isOpen={isStruggling}
          onClose={() => {
            setIsStruggling(false);
          }}
        />
        <TourErrorDialog
          isOpen={didErrorOccur}
          onClose={() => {
            setDidErrorOccur(false);
          }}
        />
        <FullscreenLoader />
      </>
    );
  }

  return (
    <Stack
      position="relative"
      maxHeight={windowDimensions.height}
      maxWidth={windowDimensions.width}
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      borderRadius={
        canvasWidth >= windowDimensions.width &&
        canvasHeight >= windowDimensions.height
          ? 0
          : '8px'
      }
      style={{objectPosition: 'center'}}
    >
      {tour?.tourIntroLogoURL && (
        <LogoCanvas
          logoUrl={tour.tourIntroLogoURL}
          width={fullScreenParam === 'true' ? window.innerWidth : canvasWidth}
          height={
            fullScreenParam === 'true' ? window.innerHeight : canvasHeight
          }
          percentage={progress}
        />
      )}
      <TourErrorDialog
        isOpen={didErrorOccur}
        onClose={() => {
          setDidErrorOccur(false);
        }}
      />
      <canvas
        style={{
          width: fullScreenParam === 'true' ? '100vw' : canvasWidth,
          height: fullScreenParam === 'true' ? '100svh' : canvasHeight,
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        ref={tourCanvasRef}
      />
      <BorderLinearProgress
        variant="buffer"
        value={progress > 0 ? progress + 1 : progress}
        valueBuffer={buffer}
      />
    </Stack>
  );
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 5,
  borderRadius: 5,
}));
