import React, {useCallback, useEffect, useState} from 'react';
import * as yup from 'yup';

import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {useAuthContext} from '../../../context/AuthContext';
import {useHistory} from 'react-router';
import {ConfigService, LoginService} from '../../../utils';
import TurnstileWidget from '../../signup/components/TurnstileWidget';
import GoogleLoginButton from '../../../components/button/GoogleLoginButton';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Email} from '@mui/icons-material';
import {UsedLastChip} from './UsedLastChip';

const loginService = new LoginService(
  ConfigService.serverUri || 'http://localhost:5000'
);

interface LoginValues {
  email: string;
  password: string;
  turnstileToken?: string;
}

const loginValidationSchema: yup.SchemaOf<LoginValues> = yup.object({
  email: yup
    .string()
    .trim()
    .email('Not a valid email.')
    .required('Email is a required field.'),
  password: yup.string().required('Password is a required field.'),
  turnstileToken: yup.string(),
});

export function LoginForm() {
  const {handleLogin} = useAuthContext();
  const {push} = useHistory();
  const [loginStatus, setLoginStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [errorMessage, setErrorMessage] = useState<string>(
    'Unfortunately we were unable to log you in. Confirm your username and password are correct.'
  );
  const [prevLoginMethod, setPrevLoginMethod] = useState<
    'truvu' | 'google' | null
  >(null);

  const handleSubmit = useCallback<FormOnSubmit<LoginValues>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);
      setLoginStatus('loading');

      try {
        const response = await loginService.login(
          values.email.trim().toLowerCase(),
          values.password
        );
        if (
          response &&
          'access_token' in response &&
          typeof response.access_token === 'string'
        ) {
          handleLogin(values.email, response.access_token, 'truvu');
          push('/');
          setLoginStatus('success');
        } else {
          setLoginStatus('error');
        }
      } catch (e) {
        setErrorMessage(e.message);
        setLoginStatus('error');
      } finally {
        setSubmitting(false);
      }
    },
    [handleLogin, push]
  );

  useEffect(() => {
    const prevLoginMethodLocalStorage = localStorage.getItem('prevLoginMethod');
    if (prevLoginMethodLocalStorage === 'truvu') {
      setPrevLoginMethod('truvu');
    } else if (prevLoginMethodLocalStorage === 'google') {
      setPrevLoginMethod('google');
    }
  }, []);

  return (
    <Form<LoginValues>
      initialValues={{email: '', password: '', turnstileToken: ''}}
      onSubmit={handleSubmit}
      validationSchema={loginValidationSchema}
    >
      <TruvuMessageDialog
        title={'Something went wrong...'}
        variant={'error'}
        isOpen={loginStatus === 'error'}
        message={
          <Stack>
            <Typography variant="body1" gutterBottom>
              {errorMessage}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              For more information contact{' '}
              <a href="mailto: support@truvu.app">support@truvu.app</a>
            </Typography>
          </Stack>
        }
        actions={
          <>
            <TruvuButton
              variant={'secondary'}
              onClick={() => {
                setLoginStatus('idle');
              }}
            >
              Close
            </TruvuButton>
          </>
        }
        onClose={() => {
          setLoginStatus('idle');
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
      />
      <TruvuTextField name="email" placeholder="E-mail" formikField />
      <TruvuTextField
        name="password"
        placeholder="Password"
        isPassword
        formikField
      />
      <TurnstileWidget />
      <TruvuButton
        startIcon={<Email color={'inherit'} fontSize={'inherit'} />}
        formikSubmit
      >
        Log in with email
      </TruvuButton>
      {prevLoginMethod === 'truvu' && <UsedLastChip />}
      <DividerWithChildren sx={{my: 1}}>or</DividerWithChildren>
      <LoginWithGoogleButton />
      {prevLoginMethod === 'google' && <UsedLastChip />}
    </Form>
  );
}

const DividerWithChildren = styled(Divider)(() => ({
  '&::before, &::after': {
    borderTopWidth: '3px',
  },
}));

const LoginWithGoogleButton: React.FC = () => {
  return <GoogleLoginButton customState={{}} gToken={''} />;
};
