import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {TruvuTextField} from '../../../components/textField';
import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {TruvuDialog} from '../../../components/dialog';
import {ArrowBack} from '@mui/icons-material';
import {ProfileCompanyDetails_user$key} from '../../../__generated__/ProfileCompanyDetails_user.graphql';
import TruvuLogo from '../../../assets/svg/truvu-logo.svg';
import {ProfileEditCompanyDetails} from './ProfileEditCompanyDetails';

const fragment = graphql`
  fragment ProfileCompanyDetails_user on Company {
    ...ProfileEditCompanyDetails_user
    id
    name
    countrycode
    referredby
    address
    vatno
    logoimageurl
  }
`;

interface ProfileCompanyDetailsProps {
  companyRef: ProfileCompanyDetails_user$key;
  refetch: () => void;
  openEditDialog: boolean;
  onCloseEditDialog: () => void;
  onOpenEditDialog: () => void;
}

export function ProfileCompanyDetails({
  companyRef,
  refetch,
  openEditDialog,
  onCloseEditDialog,
  onOpenEditDialog,
}: ProfileCompanyDetailsProps) {
  const company = useFragment(fragment, companyRef);

  return (
    <>
      <TruvuDialog
        open={openEditDialog}
        onClose={onCloseEditDialog}
        title="Edit your profile"
        closeIcon={<ArrowBack />}
        contentScrollable
      >
        <ProfileEditCompanyDetails
          companyRef={company}
          onCompleted={() => {
            refetch();
            onCloseEditDialog();
          }}
          onCancel={onCloseEditDialog}
        />
      </TruvuDialog>
      <Stack py={2} spacing={1.5}>
        <Stack spacing={1.5} direction="row" justifyContent={'space-between'}>
          <Typography variant="h2">Company Details</Typography>
          <TruvuButton
            variant="textPrimary"
            size="small"
            onClick={onOpenEditDialog}
            disableAdornments
          >
            Edit
          </TruvuButton>
        </Stack>
        <Stack
          backgroundColor={'#EDEFF2'}
          borderRadius={'16px'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'152px'}
        >
          <img
            src={TruvuLogo}
            alt="Truvu"
            style={{maxWidth: '156px', maxHeight: '100px'}}
          />
        </Stack>
        <TruvuTextField
          name="name"
          label="Company Name"
          value={company.name}
          displayOnly
        />
        <TruvuTextField
          name="address"
          label="Comapny Address"
          value={company.address}
          displayOnly
        />
        <TruvuTextField
          name="vatNumber"
          label="VAT Number"
          value={company.vatno}
          displayOnly
        />
        <TruvuTextField
          name="country"
          label="Country"
          value={company.countrycode}
          displayOnly
        />
        <TruvuTextField
          name="referredby"
          label="Referred By"
          value={company.referredby}
          displayOnly
        />
      </Stack>
    </>
  );
}
