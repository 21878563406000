import React, {useCallback, useMemo, useState} from 'react';
import {Stack, Typography, IconButton} from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {useMutation} from 'react-relay-mutation';
import {TourReelsGenerateReelMutation} from '../../../__generated__/TourReelsGenerateReelMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {TourReelPlayer} from './TourReelPlayer';
import TourReelCard, {TourReel} from './TourReelCard';
import {TruvuMenu} from '../../../components/TruvuMenu';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
import {Add} from '@mui/icons-material';
import {TourCard_tour} from '../../../__generated__/TourCard_tour.graphql';

interface TourReelsProps {
  tour: TourCard_tour;
  refetchTour: () => void;
}

const TourReels = ({tour, refetchTour}: TourReelsProps) => {
  const [currentReel, setCurrentReel] = useState<TourReel | null>(null);
  const [generatingReel, setGeneratingReel] = useState(false);
  const {notify} = useSnackbarContext();

  const [tourGenerateReel] = useMutation<TourReelsGenerateReelMutation>(
    graphql`
      mutation TourReelsGenerateReelMutation($tourId: ID!) {
        tourGenerateReel(input: {tourId: $tourId}) {
          reel {
            id
          }
        }
      }
    `
  );

  const filteredReels = useMemo(
    () => tour.reels?.filter(({status}) => status.status !== 'FAILED'),
    [tour?.reels]
  );

  const handleGenerateReel = useCallback(async () => {
    if (filteredReels.length >= 5) {
      notify({message: 'Maximum number of reels reached', variant: 'warning'});
      return;
    }

    setGeneratingReel(true);
    try {
      await tourGenerateReel({
        variables: {
          tourId: tour.id,
        },
      });
      refetchTour();
    } catch (e) {
      notify({message: 'Could not generate reel', variant: 'error'});
    } finally {
      setGeneratingReel(false);
    }
  }, [filteredReels.length, tour.id, notify, tourGenerateReel, refetchTour]);

  const handlePlay = (reel: TourReel) => {
    setCurrentReel(reel);
  };

  const handleStop = () => {
    setCurrentReel(null);
  };

  return (
    <>
      <TruvuMenu
        title="Reels"
        action={
          <TruvuIconButton
            label="Generate new"
            onClick={handleGenerateReel}
            disabled={filteredReels?.length >= 5 || generatingReel}
          >
            <Add />
          </TruvuIconButton>
        }
        alwaysSpaceBetween
      />
      {currentReel && <TourReelPlayer src={currentReel.highURI} />}
      <Stack spacing={1}>
        {filteredReels?.map((reel) => (
          <TourReelCard
            key={reel.id}
            reel={reel}
            isPlaying={currentReel === reel}
            onRetry={handleGenerateReel}
            refetchTour={refetchTour}
            onPlay={handlePlay}
            onStop={handleStop}
          />
        ))}
        {generatingReel && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{width: '100%', bgcolor: 'action.hover', borderRadius: 2, p: 1}}
          >
            <IconButton size={'small'} disabled>
              <PlayArrowRoundedIcon fontSize={'inherit'} />
            </IconButton>
            <Typography
              variant="body1"
              color="text.primary"
              fontWeight={700}
              flex={1}
            >
              New Reel
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Generating...
            </Typography>
          </Stack>
        )}
      </Stack>

      <Typography variant="caption" align="center" color="text.secondary">
        You can generate up to 5 unique reels.
      </Typography>
    </>
  );
};

export default TourReels;
