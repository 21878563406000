import React, {useRef, useState} from 'react';
import {Stack, Typography, LinearProgress, Avatar} from '@mui/material';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import House from '@mui/icons-material/House';
import {useSubscription} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {TourReelCardSubscription} from '../../../__generated__/TourReelCardSubscription.graphql';
import Button from '@mui/material/Button';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
import {TourCard_tour} from '../../../__generated__/TourCard_tour.graphql';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

const subscriptionSpec = graphql`
  subscription TourReelCardSubscription($reelId: ID!) {
    reelProgressUpdated(reelId: $reelId) {
      id
      status
      progress
      thumbnailURL
    }
  }
`;

export type TourReel = TourCard_tour['reels'][number];

interface TourReelCardProps {
  reel: TourReel;
  refetchTour: () => void;
  isPlaying: boolean;
  onRetry: (reel: TourReel) => void;
  onPlay: (reel: TourReel) => void;
  onStop: (reel: TourReel) => void;
}

const TourReelCard = ({
  reel,
  refetchTour,
  onRetry,
  isPlaying,
  onPlay,
  onStop,
}: TourReelCardProps) => {
  const [reelProgress, setReelProgress] = useState(reel.progress || 0);
  const [reelStatus, setReelStatus] = useState(reel.status.status);

  const [, setDownloadReelStatus] = useState<
    'idle' | 'downloading' | 'downloaded' | 'empty' | 'error'
  >('idle');
  const downloadTimeoutRef = useRef<NodeJS.Timeout>();
  useSubscription<TourReelCardSubscription>(
    React.useMemo(
      () => ({
        subscription: subscriptionSpec,
        variables: {
          reelId: reel.id,
        },
        onNext: (response) => {
          if (response?.reelProgressUpdated) {
            setReelProgress(response.reelProgressUpdated.progress);
            setReelStatus(response.reelProgressUpdated.status);
          }
          if (response?.reelProgressUpdated.progress === 100) {
            setTimeout(() => {
              refetchTour();
              setReelStatus('CREATED');
              setReelProgress(0);
            }, 500);
          }
        },
      }),
      [reel.id, refetchTour]
    )
  );

  const handlePlay = () => {
    onPlay(reel);
  };

  const handlePause = () => {
    onStop(reel);
  };

  const onReelDownload = async () => {
    if (
      reel == null ||
      reel.status?.status === 'FAILED' ||
      reel.status?.status === 'PROCESSING'
    ) {
      setDownloadReelStatus('empty');
      downloadTimeoutRef.current = setTimeout(() => {
        setDownloadReelStatus('idle');
      }, 6000);
      return;
    }

    setDownloadReelStatus('downloading');
    try {
      const response = await fetch(reel.highURI);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = reel.key + '_reel.mp4'; // You can set the desired file name here
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setDownloadReelStatus('downloaded');
    } catch (error) {
      setDownloadReelStatus('error');
    } finally {
      downloadTimeoutRef.current = setTimeout(() => {
        setDownloadReelStatus('idle');
      }, 6000);
    }
  };

  if (reelStatus === 'FAILED') {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        bgcolor={'background.paper'}
        sx={{
          width: '100%',
          borderRadius: 2,
          p: 1,
          opacity: 0.8,
        }}
      >
        <ReportProblemRoundedIcon
          fontSize={'medium'}
          sx={{color: 'error.main'}}
        />
        <Typography variant="body1" color="text.primary" flex={1}>
          Generation Failed {reel.name != null ? `for ${reel.name}` : ''}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          startIcon={<RefreshRoundedIcon />}
          onClick={() => onRetry(reel)}
          color={'error'}
        >
          Retry
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        bgcolor={isPlaying ? 'secondary.main' : 'background.paper'}
        sx={{width: '100%', borderRadius: 2, p: 1}}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <TruvuIconButton
            size={'small'}
            onClick={isPlaying ? handlePause : handlePlay}
            variant={'primary'}
          >
            {isPlaying ? (
              <StopRoundedIcon fontSize={'inherit'} />
            ) : (
              <PlayArrowRoundedIcon fontSize={'inherit'} />
            )}
          </TruvuIconButton>
          <Avatar
            src={reel.thumbnailURL}
            alt={`Thumbnail for ${reel.name}`}
            variant="rounded"
            sx={{width: 42, height: 42}}
          >
            {/* Fallback content if thumbnail fails to load */}
            <House fontSize={'inherit'} />
          </Avatar>
          <Typography
            variant="body1"
            color={isPlaying ? 'primary' : 'text.primary'}
            fontWeight={700}
            flex={1}
          >
            {reel.name}
          </Typography>
          {reelStatus === 'PROCESSING' ? (
            <Typography variant="body2" color="text.secondary">
              Processing: {Math.round(reelProgress)}%
            </Typography>
          ) : (
            <>
              <Button
                size={'small'}
                variant="outlined"
                color={'primary'}
                startIcon={
                  <DownloadRoundedIcon
                    sx={{fontSize: 16, width: 16, height: 16}}
                  />
                }
                onClick={onReelDownload}
              >
                Download
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      {reelStatus === 'PROCESSING' && (
        <LinearProgress
          variant="determinate"
          value={reelProgress}
          sx={{height: 8, borderRadius: 4}}
        />
      )}
    </>
  );
};

export default TourReelCard;
