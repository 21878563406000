import React from 'react';

import {Avatar, Divider, Stack, styled} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import {ProfileDetails_user$key} from '../../../__generated__/ProfileDetails_user.graphql';
import {useRefetchable} from 'relay-hooks';
import {TruvuMenu} from '../../../components/TruvuMenu';
import Typography from '@mui/material/Typography';
import {ProfilePlanDetails} from './ProfilePlanDetails';
import {ProfileAccountDetails} from './ProfileAccountDetails';
import {ProfileAddDetails} from './ProfileAddDetails';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useAuthContext} from '../../../context/AuthContext';
import {useHistory} from 'react-router';
import {
  TruvuAlert,
  TruvuAlertProps,
} from '../../../components/alerts/TruvuAlert';
import {ResendEmailActivation} from '../../tourLanding/components/ResendEmailActivation';
import {checkUploadLimit} from '../../tourLanding/helpers/checkUploadLimit';
import {TruvuAppBar} from '../../../components/containers/TruvuAppBar';
import {
  PlayerTerms,
  PlayerTermTypography,
} from '../../tourPlayer/components/TourMenu';
import {useTruvuDialog} from '../../../components/dialog';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {ProfileCompanyDetails} from './ProfileCompanyDetails';

interface ProfileDetailsProps {
  userRef: ProfileDetails_user$key;
}

const fragment = graphql`
  fragment ProfileDetails_user on User
  @refetchable(queryName: "ProfileTodoRefetchQuery") {
    ...ProfileEditDetails_user
    ...ProfilePlanDetails_user
    ...ProfileAccountDetails_user
    id
    roles
    profile {
      name
      surname
      profileImageURL
      email
    }
    company {
      ...ProfileCompanyDetails_user
      invites {
        accepted
        createdAt
        email
        expiresAt
      }
      license {
        planName
        stripeSubscriptionId
        monthlyActiveTours
        monthlyUploadedTours
        uploadLimit
        quantity
      }
    }
  }
`;

export function ProfileDetails({userRef}: ProfileDetailsProps) {
  const {data: user, refetch} = useRefetchable(fragment, userRef);
  const {handleLogout} = useAuthContext();
  const history = useHistory();
  const {
    openDialog: openEditDialog,
    onOpenDialog: onOpenEditDialog,
    onCloseDialog: onCloseEditDialog,
  } = useTruvuDialog(false);
  const {
    openDialog: openEditCompanyDialog,
    onOpenDialog: onOpenEditCompanyDialog,
    onCloseDialog: onCloseEditCompanyDialog,
  } = useTruvuDialog(false);
  const {
    openDialog: openUpgradeDialog,
    onOpenDialog: onOpenUpgradeDialog,
    onCloseDialog: onCloseUpgradeDialog,
  } = useTruvuDialog();

  const goHome = () => {
    history.push('/');
  };

  if (user.profile == null) {
    return <ProfileAddDetails userRef={user} refetch={() => refetch({})} />;
  }
  const {name = '', surname = ''} = user?.profile || {};
  const initials = `${name
    .trim()[0]
    ?.toUpperCase()}${surname.trim()[0]?.toUpperCase()}`;

  const isAdmin = user && user.roles && user.roles.includes('admin');

  const companyLicense = user.company?.license;

  const {company, profile} = user;

  const alerts: TruvuAlertProps[] = [];

  const accountActivated = company?.license != null;

  if (!accountActivated) {
    alerts.push({
      label: 'Account status',
      message: 'Not activated',
      severity: 'error',
      popup: {
        label: 'Activate your account',
        message:
          'Please click the link in your email to activate your account.',
        actions: <ResendEmailActivation email={profile?.email ?? ''} />,
      },
    });
  }

  const {isActiveAvailable, isUploadAvailable} = checkUploadLimit({
    monthlyActiveTours: company?.license?.monthlyActiveTours,
    monthlyUploadedTours: company?.license?.monthlyUploadedTours,
    uploadLimit: company?.license?.uploadLimit,
    quantity: company?.license?.quantity,
  });

  if (accountActivated && !isActiveAvailable) {
    alerts.push({
      label: 'Membership',
      message: 'No active tours left',
      severity: 'error',
      popup: {
        label: 'Upgrade your account',
        message: 'Upgrade to a larger plan in order to upload more tours',
        actions: (
          <TruvuButton variant="danger" to="/account/subscriptions">
            Upgrade plan
          </TruvuButton>
        ),
      },
    });
  }
  if (accountActivated && !isUploadAvailable) {
    alerts.push({
      label: 'Membership',
      message: 'No monthly uploads left',
      severity: 'error',
      popup: {
        label: 'Upgrade your account',
        message: 'Upgrade to a larger plan in order to upload more tours',
        actions: (
          <TruvuButton variant="danger" to="/account/subscriptions">
            Upgrade plan
          </TruvuButton>
        ),
      },
    });
  }

  return (
    <Stack maxHeight={'100svh'}>
      <ProfileContainer>
        <TruvuAppBar onGoBack={goHome} />
      </ProfileContainer>
      <ProfileScrollContainer>
        <TruvuMenu
          title={name ? `${name} ${surname ?? ''}` : 'N/A'}
          subtitle={user.company?.license?.planName ?? 'Email not verified'}
          subtitleColor={user.company?.license?.planName ? 'primary' : 'error'}
          action={
            user.profile?.profileImageURL != null &&
            user.profile?.profileImageURL !== '' ? (
              <Avatar
                alt="Remy Sharp"
                src={user.profile.profileImageURL}
                sx={{width: 60, height: 60}}
                onClick={onOpenEditDialog}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#dfe2e6',
                  color: '#fff',
                  width: 60,
                  height: 60,
                }}
                onClick={onOpenEditDialog}
              >
                <Typography fontSize="1.4em" fontWeight={700}>
                  {initials}
                </Typography>
              </Avatar>
            )
          }
          alwaysSpaceBetween
        />
        <Stack direction="row" spacing={1} my={1}>
          {isAdmin && companyLicense && (
            <TruvuButton
              disableAdornments
              size="default"
              variant="primary"
              sx={{py: 1}}
              to="/account/subscriptions"
            >
              Upgrade
            </TruvuButton>
          )}
          <TruvuMessageDialog
            title="Not Available"
            message="Teams are not available on your current package."
            variant="error"
            actions={
              <TruvuButton
                disableAdornments
                size="default"
                variant="secondary"
                sx={{py: 1}}
                to={'/account/subscriptions'}
              >
                Upgrade
              </TruvuButton>
            }
            onOpen={onOpenUpgradeDialog}
            isOpen={openUpgradeDialog}
            onClose={onCloseUpgradeDialog}
          />
          {isAdmin && companyLicense && (
            <TruvuButton
              disableAdornments
              size="default"
              variant="secondary"
              sx={{py: 1}}
              onClick={() => {
                if (
                  companyLicense.planName !== 'Basic' &&
                  companyLicense.planName !== 'Pro'
                ) {
                  onOpenUpgradeDialog();
                } else {
                  history.push('/team');
                }
              }}
            >
              My Team
            </TruvuButton>
          )}
        </Stack>
        {alerts.length > 0 && (
          <>
            <Typography variant="h2" my={1} fontWeight={700}>
              Alerts
            </Typography>
            <Stack mb={2} spacing={1.5} useFlexGap>
              {alerts.map((alert, i) => (
                <TruvuAlert key={`${alert.label}${i}`} {...alert} />
              ))}
            </Stack>
          </>
        )}
        {/*<Stack sx={{overflowY: 'auto'}} p={0.5}>*/}
        <ProfilePlanDetails userRef={user} />
        <ProfileAccountDetails
          userRef={user}
          refetch={() => refetch({})}
          openEditDialog={openEditDialog}
          onCloseEditDialog={onCloseEditDialog}
          onOpenEditDialog={onOpenEditDialog}
        />
        {user.company != null && (
          <ProfileCompanyDetails
            companyRef={user.company}
            refetch={() => refetch({})}
            openEditDialog={openEditCompanyDialog}
            onCloseEditDialog={onCloseEditCompanyDialog}
            onOpenEditDialog={onOpenEditCompanyDialog}
          />
        )}
        <Divider sx={{mb: 2}} />
        <TruvuButton variant="danger" onClick={handleLogout}>
          Log out
        </TruvuButton>

        <PlayerTerms py={1.5}>
          <PlayerTermTypography
            onClick={() => {
              history.push('/privacy');
            }}
          >
            Privacy
          </PlayerTermTypography>
          <PlayerTermTypography>/</PlayerTermTypography>
          <PlayerTermTypography
            onClick={() => {
              history.push('/terms');
            }}
          >
            Terms
          </PlayerTermTypography>
          <PlayerTermTypography>/</PlayerTermTypography>
          <PlayerTermTypography
            onClick={() => {
              history.push('/licenses');
            }}
          >
            Licenses
          </PlayerTermTypography>
        </PlayerTerms>
        {/*</Stack>*/}
      </ProfileScrollContainer>
    </Stack>
  );
}

const ProfileContainer = styled(Stack)({
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px',
  maxWidth: '500px',
});

const ProfileScrollContainer = styled(Stack)({
  overflow: 'auto',
  flex: 1,
  flexGrow: 1,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  flexDirection: 'column',
  padding: '0 20px 20px',
  maxWidth: '500px',
});
