import React, {useState} from 'react';
import {Card, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {styled} from '@mui/material/styles';
import {useMutation} from 'react-relay-mutation';
import {SubscriptionCardMutation} from '../../../__generated__/SubscriptionCardMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {SubscriptionCardManageDialog} from './SubscriptionCardManageDialog';
import {useTruvuDialog} from '../../../components/dialog/TruvuDialog';
import {SubscriptionCardErrorDialog} from './SubscriptionCardErrorDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface SubscriptionCardProps {
  isActivePlan?: boolean;
  priceId: string;
  label: string;
  tierName: string;
  price: number;
  description: string;
  activeToursCount: number;
  uploadLimitCount: number;
  currencyCode: string;
  currencySymbol: string;
}

export function SubscriptionCard({
  isActivePlan,
  priceId,
  activeToursCount,
  description,
  tierName,
  price,
  label,
  currencyCode,
  currencySymbol,
}: SubscriptionCardProps) {
  const {notify} = useSnackbarContext();
  const [paymentCheckout, {loading}] = useMutation<SubscriptionCardMutation>(
    graphql`
      mutation SubscriptionCardMutation($input: PaymentCheckoutInput!) {
        paymentCheckout(input: $input) {
          url
          message
          error
        }
      }
    `
  );
  const isFree = price === 0;

  const [message, setMessage] = useState('');
  const handleActivatePlan = async () => {
    const response = await paymentCheckout({
      variables: {
        input: {
          priceId: priceId,
          quantity: activeToursCount,
        },
      },
    });

    if (response.paymentCheckout.message !== '') {
      setMessage(response.paymentCheckout.message);
      manageDialog.onOpen();
      return;
    }
    if (response.paymentCheckout.error !== '') {
      setMessage(response.paymentCheckout.message);
      errorDialog.onOpen();
      return;
    }
    if (
      response.paymentCheckout.error != null &&
      response.paymentCheckout.error !== '' &&
      response.paymentCheckout.message !== ''
    ) {
      notify({message: response.paymentCheckout.message, variant: 'error'});
      return;
    }
    if (response.paymentCheckout.url != null) {
      // Redirect to Stripe's checkout page
      window.location.href = response.paymentCheckout.url;
    }
  };

  const theme = useTheme();

  const manageDialog = useTruvuDialog();
  const errorDialog = useTruvuDialog();
  return (
    <>
      <Card
        elevation={0}
        sx={{
          flex: 1,
          minWidth: '250px',
          maxWidth: '450px',
          display: 'flex',
          flexDirection: 'column',
          p: 1.5,
          border: isActivePlan ? '2px solid #5374FC' : 'undefined',
        }}
      >
        <SubscriptionCardManageDialog {...manageDialog} message={message} />
        <SubscriptionCardErrorDialog {...errorDialog} message={message} />
        <TruvuButton
          size="default"
          variant={isActivePlan ? 'secondary' : 'primary'}
          sx={{alignSelf: 'flex-start', py: 1, px: 2.5}}
          disableRipple
          disableTouchRipple
          disableAdornments
        >
          {label}
        </TruvuButton>
        <Stack my={2} flex={1}>
          <Stack direction="row" alignItems="flex-end" mb={1} spacing={1}>
            <Typography variant="h1">
              {price > 0
                ? `${currencySymbol} ${price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
                : 'Free'}{' '}
            </Typography>
            {price > 0 && <Typography>/ month</Typography>}
          </Stack>
          <Typography variant={'h6'}>{description}</Typography>
          <Typography
            variant={'h6'}
            sx={{
              fontSize: '0.875rem',
              color: 'grey.600',
            }}
          >
            For every tour, you get 2 attempts to upload and process.
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.primary">Tours</Typography>
          <Typography color="text.secondary">{activeToursCount}</Typography>
        </Stack>
        <Divider />
        {tierName === 'basic' || tierName === 'pro' ? (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.primary">Invite Team</Typography>
              <CheckCircleIcon style={{color: theme.palette.primary.main}} />
            </Stack>
            <Divider />
          </>
        ) : null}

        {tierName === 'pro' ? (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.primary">Tour Analytics</Typography>
              <CheckCircleIcon style={{color: theme.palette.primary.main}} />
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.primary">RoomRate AI</Typography>
              <Typography color="text.secondary">coming soon</Typography>
            </Stack>
            <Divider />
          </>
        ) : null}

        <TruvuButton
          sx={{mt: 1}}
          variant={isActivePlan ? 'secondary' : 'primary'}
          onClick={handleActivatePlan}
          disabled={isActivePlan}
          loading={loading}
        >
          {isActivePlan
            ? 'Current Plan'
            : isFree
            ? 'Start for free'
            : 'Activate Plan'}
        </TruvuButton>
      </Card>
    </>
  );
}

const Divider = styled('div')(({theme}) => ({
  height: '4px',
  display: 'flex',
  alignSelf: 'stretch',
  backgroundColor: theme.palette.divider,
  borderRadius: 8,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));
