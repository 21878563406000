/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileTodoRefetchQueryVariables = {
    id: string;
};
export type ProfileTodoRefetchQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"ProfileDetails_user">;
    } | null;
};
export type ProfileTodoRefetchQuery = {
    readonly response: ProfileTodoRefetchQueryResponse;
    readonly variables: ProfileTodoRefetchQueryVariables;
};



/*
query ProfileTodoRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...ProfileDetails_user
    id
  }
}

fragment ProfileAccountDetails_user on User {
  ...ProfileEditDetails_user
  id
  profile {
    id
    name
    surname
    company
    email
    contactNumber
    profileImageURL
  }
}

fragment ProfileCompanyDetails_user on Company {
  ...ProfileEditCompanyDetails_user
  id
  name
  countrycode
  referredby
  address
  vatno
  logoimageurl
}

fragment ProfileDetails_user on User {
  ...ProfileEditDetails_user
  ...ProfilePlanDetails_user
  ...ProfileAccountDetails_user
  id
  roles
  profile {
    name
    surname
    profileImageURL
    email
    id
  }
  company {
    ...ProfileCompanyDetails_user
    invites {
      accepted
      createdAt
      email
      expiresAt
      id
    }
    license {
      planName
      stripeSubscriptionId
      monthlyActiveTours
      monthlyUploadedTours
      uploadLimit
      quantity
      id
    }
    id
  }
}

fragment ProfileEditCompanyDetails_user on Company {
  id
  logoimageurl
  name
  address
  vatno
  countrycode
}

fragment ProfileEditDetails_user on User {
  id
  profile {
    id
    name
    surname
    email
    contactNumber
    profileImageURL
  }
}

fragment ProfilePlanDetails_user on User {
  monthlyActiveTours
  monthlyUploadedTours
  company {
    name
    license {
      stripeSubscriptionId
      monthlyActiveTours
      monthlyUploadedTours
      planName
      quantity
      uploadLimit
      startDate
      endDate
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyActiveTours",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyUploadedTours",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileDetails_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileTodoRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surname",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "company",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "license",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripeSubscriptionId",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uploadLimit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoimageurl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vatno",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countrycode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referredby",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamInvite",
                    "kind": "LinkedField",
                    "name": "invites",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accepted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiresAt",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6a2cdcdc7d3b58c901a2a5f1399fe78",
    "id": null,
    "metadata": {},
    "name": "ProfileTodoRefetchQuery",
    "operationKind": "query",
    "text": "query ProfileTodoRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ProfileDetails_user\n    id\n  }\n}\n\nfragment ProfileAccountDetails_user on User {\n  ...ProfileEditDetails_user\n  id\n  profile {\n    id\n    name\n    surname\n    company\n    email\n    contactNumber\n    profileImageURL\n  }\n}\n\nfragment ProfileCompanyDetails_user on Company {\n  ...ProfileEditCompanyDetails_user\n  id\n  name\n  countrycode\n  referredby\n  address\n  vatno\n  logoimageurl\n}\n\nfragment ProfileDetails_user on User {\n  ...ProfileEditDetails_user\n  ...ProfilePlanDetails_user\n  ...ProfileAccountDetails_user\n  id\n  roles\n  profile {\n    name\n    surname\n    profileImageURL\n    email\n    id\n  }\n  company {\n    ...ProfileCompanyDetails_user\n    invites {\n      accepted\n      createdAt\n      email\n      expiresAt\n      id\n    }\n    license {\n      planName\n      stripeSubscriptionId\n      monthlyActiveTours\n      monthlyUploadedTours\n      uploadLimit\n      quantity\n      id\n    }\n    id\n  }\n}\n\nfragment ProfileEditCompanyDetails_user on Company {\n  id\n  logoimageurl\n  name\n  address\n  vatno\n  countrycode\n}\n\nfragment ProfileEditDetails_user on User {\n  id\n  profile {\n    id\n    name\n    surname\n    email\n    contactNumber\n    profileImageURL\n  }\n}\n\nfragment ProfilePlanDetails_user on User {\n  monthlyActiveTours\n  monthlyUploadedTours\n  company {\n    name\n    license {\n      stripeSubscriptionId\n      monthlyActiveTours\n      monthlyUploadedTours\n      planName\n      quantity\n      uploadLimit\n      startDate\n      endDate\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2e61884724ebfb9df234518bc8d103ea';
export default node;
